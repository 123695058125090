#competencies {
  position: relative;
  width: 55%;

  label {
    display: block;
    padding: 10px 0;
  }

  .assessment-error {
    color: red;
  }

  .MuiPaper-root {
    display: flex;
    justify-content: center;

    label {
      left: 18px;
      position: absolute;
      top: 100px;
    }

    .MuiTabs-root {
      padding: 5px 10px;
    }

    .MuiTab-root {

      &.Mui-selected {
        background: #fda82e;
        border-radius: 15px;
      }
    }
  }

  .recharts-text {
    cursor: pointer;
    text-decoration: underline;

    tspan {
      display: block;
    }
  }

  .comments {
    display: flex;
    flex-direction: column;

    .input {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      width: 100%;

      label {
        padding: unset;
      }

      .MuiFormControl-root {
        margin: 15px 0;
        width: 100%;
      }
    }
  }

  .save {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;

    .assessment-name-input {
      position: relative;

      .MuiFormLabel-root {
        padding: 0;
      }
    }

    .assessment-name {
      display: flex;
      width: 100%;
      border: 1px solid #b3b1b1;
      background-color: #f5f5f5;
      padding-top: 5px;
      padding-left: 10px;


      .title {
        width: 100%;
        font-weight: bold;
      }

      .date {
        flex: 1;
        padding-right: 10px;
        font-size: 11px;
        align-items: center;
        text-align: right;
      }
    }

    span {
      display: flex;
      flex-direction: row;
      width: 100%;
      padding-top: -5px;

      &.MuiInputLabel-asterisk {
        display: inline;
        padding: 0;
        width: auto;
      }
    }
  }

  .comments-accordion {
    display: flex;
    flex-direction: column;
    background: #f5f5f5;

    .comments-header {

      sup {
        font-size: 11px;
        margin: 4px 0 0 7px;
      }
    }

    .comments-details {
      flex: 1;
      max-height: 250px;
      background-color: #fff;
      overflow: auto;

      .comments-list {
        font-size: 12px;

        .comments-item {
          flex-direction: column;
          align-items: flex-start;
          border: 1px solid #b3b1b1;
          border-radius: 10px;
          margin-bottom: 6px;
          box-shadow: 1px 1px #b3b1b1;

          .user-date {
            display: flex;
            width: 100%;

            .user {
              width: 100%;
              font-weight: bold;
            }

            .date {
              font-size: 10px;
            }
          }

          .comment {
            padding-left: 10px;
          }
        }
      }
    }
  }
}