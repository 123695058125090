#modal {

  .MuiBox-root {
    display: grid;

    &:focus-visible {
      outline: none;
    }

    article {
      left: 40px;
      position: fixed;
      top: 20px;
      width: 35%;
    }

    aside {
      justify-self: flex-end;
      margin-right: 20px;
      max-width: 60%;
      overflow: auto;

      > ul {
        display: table;

        > li {
          display: table-row;
          vertical-align: top;
        }

        h2 {
          display: table-cell;
          padding: 5px 0 10px;
          position: relative;

          &.link {
            cursor: pointer;
            text-decoration: underline;

            &:hover:before {
              content:"\A";
              border-style: solid;
              border-width: 10px 0 10px 15px;
              border-color: transparent transparent transparent #000;
              left: -22px;
              position: absolute;
              top: 13px;
            }
          }
        }

        p {
          display: table-cell;
          padding: 0;

          > ul {
            margin: 0;

            > li {
              display: list-item;
              list-style: disc;

              p {
                margin: 0 0 5px;
              }
            }
          }
        }
      }
    }

    .MuiIconButton-root {
      position: absolute;
      right: 6px;
      top: 6px;
    }

    .selected h2 {
      color: #01bac1;

      &:before {
        content:"\A";
        border-style: solid;
        border-width: 10px 0 10px 15px;
        border-color: transparent transparent transparent #01bac1;
        left: -20px;
        position: absolute;
        top: 12px;
      }
    }
  }

  @media (max-width: 1400px) {
    .MuiBox-root {

      aside {

        > ul {
          display: block;

          > li {
            display: block;
            max-width: 500px;
            padding: 5px;
          }

          h2 {
            display: block;
            padding: 0;
            margin: 5px 0;
          }

          p {
            display: block;
          }
        }
      }
    }
  }
}