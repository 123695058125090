#capability-info {
  width: 500px;

  label {
    display: block;
    padding: 10px 0;
  }

  ul.titles {
    display: flex;
    margin: 0 0 20px;
    padding: 0;
    width: 425px;

    li {
      flex: 0.12;
      list-style: none;

      .MuiAvatar-root {
        cursor: pointer;
      }

      &.selected {

        .MuiAvatar-root {
          background-color: #2ea2a6;
          outline: 3px solid #1bc0c6;
          outline-offset: 4px;
        }
      }
    }
  }

  .MuiAvatar-root {
    font-size: 13px;
  }

  .MuiPaper-root {
    padding: 15px;

    ul {

      p {
        margin: 0 0 0.5em;
      }
    }
  }
}