header {
  display: flex;

  h1 {
    flex: 1;
    padding: 20px 0;
    position: relative;
  }

  sup {
    font-size: 14px;
    font-weight: normal;
    left: 0;
    letter-spacing: 0;
    position: absolute;
    top: 0;
  }

  .MuiInputBase-root {

    .MuiSelect-select {
      font-family: Inter;
      font-size: 2.125rem;
      font-weight: 700;
      letter-spacing: -1.5px;
      line-height: 1.167;
      padding: 0;
    }

    fieldset {
      border: none;
    }
  }

  a {
    display: flex;
  }

  .logo {
    object-fit: contain;
    pointer-events: none;
    width: 30vmin;
  }

  .MuiIconButton-root {
    align-self: center;
  }
}

#account-menu {

  .Mui-disabled {
    opacity: unset;
  }

  .spider-chart {
    object-fit: contain;
    width: 24px;
  }
}
