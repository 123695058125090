#home {
  background-color: #282c34;
  color: white;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  height: 100vh;
  text-align: center;

  header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  main {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .logo {
    margin: 15vmin 0 10vmin;
    pointer-events: none;
  }

  .link {
    color: #61dafb;
  }
}
