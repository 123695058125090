#assessments {
  h2 {
    padding: 15px;
  }

  .MuiDrawer-paper {
    width: 370px;
  }

  .Mui-disabled  {
    color: rgba(0, 0, 0, 0.54);
  }

  .MuiListItemText-root {
    padding: 8px 16px;
  }

  .MuiListItemButton-root {

    .MuiListItemText-root {
      padding: 0;
    }
  }

  span.name {
    display: inline-block;
    vertical-align: middle;
    width: 185px;

    &.editable {
      cursor: alias
    }
  }

  span.createdDate {
    display: inline-block;
    vertical-align: middle;
  }

  .radar-chart {
    object-fit: contain;
    width: 24px;
  }

  #new-assessment {
    background: #fda82e55;
  }
}